import common from './common-educabot'

export default {
  ...common.button,
  color: `#FFFFFF`,
  '::after': {
    ...common.button['::after'],
    bg: `#2A205E`,
    borderColor: `#2A205E`,
    '@media (max-width: 500px)': {
      minWidth: '180%'
    },
    '@media (max-width: 400px)': {
      minWidth: '140%'
    },
    '@media (max-width: 330px)': {
      minWidth: '130%'
    }
  }
}