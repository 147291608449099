import common from './common-educabot'

export default {
  ...common.button,
  color: `white`,
  '::after': {
    ...common.button['::after'],
    bg: `#FF6D35`,
    borderColor: `#FF6D35`,
    '@media (max-width: 743px)': {
      borderColor: `#FFF`,
      height: '40px'
    },
  }
}