import primary from './primary'
import paper from './paper'
import paperLg from './paper-lg'
import vertical from './vertical'
import interactive from './interactive'
import verticalCover from './vertical-cover'
import horizontalAside from './horizontal-aside'
import horizontalLg from './horizontal-lg'
import horizontalHero from './horizontal-hero'
import horizontalMd from './horizontal-md'
import horizontalCover from './horizontal-cover'
import horizontalCoverWide from './horizontal-cover-wide'
import horizontalCoverHero from './horizontal-cover-hero'

export default {
  'horizontal-aside': horizontalAside,
  'horizontal-lg': horizontalLg,
  'horizontal-hero': horizontalHero,
  'horizontal-md': horizontalMd,
  'horizontal-cover': horizontalCover,
  'horizontal-cover-wide': horizontalCoverWide,
  'horizontal-cover-hero': horizontalCoverHero,
  primary,
  paper,
  'paper-lg': paperLg,
  'vertical-cover': verticalCover,
  interactive,
  vertical
}
