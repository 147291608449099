import common from './common-educabot'

export default {
  ...common.button,
  color: `#FF6D35`,
  '::before': {
    display: `none`
  },
  '::after': {
    ...common.button['::after'],
    bg: `white`,
    border: '0.5px solid',
    borderColor: `#FF6D35`
  }
}