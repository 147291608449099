export default {
    color: `omegaDark`,
    textDecoration: `none`,
    userSelect: `none`,
    cursor: `default`,
    whiteSpace: `pre`,
    display: 'flex',
    justifyContent: 'center',
    p: 0,
    ':visited': {
      color: 'omegaDark'
    },
    ':hover': {
      color: 'omegaDark'
    }
  }
  