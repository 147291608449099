import normal from './normal'
import disabled from './disabled'
import hidden from './hidden'
import navApps from './apps'
import navDark from './nav-dark'
import navLight from './nav-light'
import navWhite from './nav-white'
import navSuite from './nav-suite'
import navAulaMaker from './nav-aula-maker'
import navSmartTeam from './nav-smart-team'
import navCodit from './nav-codit'

export default {
  normal,
  disabled,
  hidden,
  'nav-dark': navDark,
  'nav-light': navLight,
  'nav-white': navWhite,
  'nav-suite': navSuite,
  'nav-aula-maker': navAulaMaker,
  'nav-smart-team': navSmartTeam,
  'nav-codit': navCodit,
  'apps': navApps
}
