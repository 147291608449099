import common from './common-educabot'

export default {
  ...common.button,
  color: `#FFFFFF`,
  '::before': {
    display: `none`
  },
  '::after': {
    ...common.button['::after'],
    bg: `#01CEAA`,
    border: '0.5px solid',
    borderColor: `#01CEAA`,
    '@media (max-width: 743px)': {
      borderColor: `#FFF`,
      height: '40px',
      marginTop: '1px'
    }
  }
}