import common from './common-educabot'

export default {
  ...common.button,
  color: `#FFFFFF`,
  '::after': {
    ...common.button['::after'],
    bg: `transparent`,
    borderColor: `#FFF`,
    borderRadius: '45px',
    height: '80px',
    left: '-9px',
    '@media (max-width: 500px)': {
      height: '80px'
    }
    // '@media (max-width: 400px)': {
    //   minWidth: '140%'
    // },
    // '@media (max-width: 330px)': {
    //   minWidth: '130%'
    // }
  }
}