import common from './common-educabot'

export default {
  ...common.button,
  color: `#FF6D35`,
  bg: `#ffffff`,
  '::before': {
    display: `none`
  },
  '::after': {
    ...common.button['::after'],
    bg: `#ffffff`,
    border: 'none',
  },
  '&:hover': {
    color: '#9e3006'
    },
}