import React, { useEffect } from 'react'
import { Global } from '@emotion/core'
import { ThemeProvider, merge, Flex, Box, css } from 'theme-ui'
import baseTheme from '@solid-ui-theme'
import pageContextProvider from '@helpers/pageContextProvider'
import { FormContextProvider } from '@solid-ui-components/ContentForm'
import { ModalContextProvider } from '@solid-ui-components/Modal'
import { TabsContextProvider } from '@solid-ui-components/Tabs'
import ColorMode from '@solid-ui-components/ColorMode'
import Whastapp from '../../../solid-ui-components/src/Whastapp/Whastapp'
import ButtonTop from '../../../solid-ui-components/src/ButtonTop/ButtonTop'

const Layout = ({ children, pageContext = {}, location, theme = {} }) => {

  useEffect(() => {
    function loadScript(a) { var b = document.getElementsByTagName("head")[0], c = document.createElement("script"); c.type = "text/javascript", c.src = "https://tracker.metricool.com/resources/be.js", c.onreadystatechange = a, c.onload = a, b.appendChild(c) } loadScript(function () { beTracker.t({ hash: "8fc21a628a95e1eef6abb081121230f0" }) })
  }, [])

  return (
    <ThemeProvider theme={merge(baseTheme, theme)}>
      <pageContextProvider.Provider value={{ pageContext, location }}>
        <FormContextProvider>
          <ModalContextProvider>
            <TabsContextProvider>
              <Flex variant='layout.layout'>
                <Global styles={css(theme => theme.global)} />
                <ColorMode />
                <Box variant='layout.body'>{children}</Box>
              </Flex>
              <ButtonTop />
              <Whastapp />
            </TabsContextProvider>
          </ModalContextProvider>
        </FormContextProvider>
      </pageContextProvider.Provider>
    </ThemeProvider>
  )
}

export default Layout
