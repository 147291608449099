export default {
    color: `omegaDarker`,
    fontWeight: `medium`,
    textDecoration: `none`,
    userSelect: `none`,
    whiteSpace: `pre`,
    transition: `all 250ms ease`,
    p: 0,
    ':visited': {
      color: 'omegaDarker'
    },
    ':hover': {
      color: '#FFB800'
    }
  }