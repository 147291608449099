import common from './common-educabot'

export default {
  ...common.button,
  color: `#FFFFFF`,
  '::before': {
    display: `none`
  },
  '::after': {
    ...common.button['::after'],
    bg: `#42B3FF`,
    border: '0.5px solid',
    borderColor: `#42B3FF`
  }
}