import { useState, useEffect, useCallback } from 'react'
import axios from 'axios'

const useForm = () => {
  const [submitting, setSubmitting] = useState(false)
  const [target, setTarget] = useState({})
  const [success, setSuccess] = useState()
  const [values, setValues] = useState()
  const [action, setAction] = useState()
  
  const handleSubmit = (e, { values, action } = {}) => {
    e.preventDefault()
    if (values) setValues(values)
    if (action) setAction(action)
    setTarget(e.target)
    setSubmitting(true)
  }


  const sendValues = useCallback(() => {
    const form = new FormData(target)
    const formData = new URLSearchParams(values || form).toString()
    const isDemo = target.getAttribute('demo')

    // Mimicking form submission for demos
    if (isDemo) {
      setTimeout(() => {
        setSuccess(true)
        setSubmitting(false)
      }, 1500)
      return
    }

    // Real form submission
    // fetch(action || target.action, {
    //   method: target.method || 'POST',
    //   headers: {
    //     'Content-Type': 'application/x-www-form-urlencoded',
    //     Accept: 'application/json'
    //   },
    //   body: formData
    // })

    axios.post(action, {
      // name,
      // email,
      // phone,
      // nationality,
      // students,
      ...values,
      source: getParam('utm_source'),
      medium: getParam('utm_medium'),
      campaign: getParam('utm_campaign'),
      adgroup: getParam('utm_adgroup'),
      term: getParam('utm_term'),
      content: getParam('utm_content'),
      landing: getParam('utm_landing'),
      gclid: getParam('gclid'),
      fbclid: getParam('fbclid')
    })
      .then(() => {
        target.reset()

        setSuccess(true)
      })
      .catch(error => {


        setSuccess(false)
      })
      .finally(() => {
        setSubmitting(false)
      })
  }, [target])

  useEffect(() => {
    if (submitting) {
      sendValues()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitting, sendValues])

  // const reset = useCallback(() => {
  //   setSubmitting(false)
  //   setSuccess(undefined)
  //   setValues(undefined)
  //   setAction(undefined)
  // }, [])

  return {
    handleSubmit,
    submitting,
    success,
    // reset
  }
}

export default useForm


/**
 * Returns the query param with the given name
 *
 * @param name Param name
 * @param url Source URL, defaults to window.location.href
 * @return {string|null}
 */
 function getParameterByName (name, url) {
  if (!url) url = window.location.href
  name = name.replace(/[\[\]]/g, '\\$&')
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url)
  if (!results) return null
  if (!results[2]) return ''
  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

/**
 * Returns the value of the cookie with the given name
 *
 * @param name
 * @return {string}
 */
function getCookie (name) {
  const value = '; ' + document.cookie
  const parts = value.split('; ' + name + '=')
  if (parts.length === 2)
    return parts.pop().split(';').shift()
  return ''
}

function setCookie (name, value, days) {
  const date = new Date()
  date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000))
  const expires = '; expires=' + date.toGMTString()
  document.cookie = name + '=' + value + expires + ';path=/'
}

function getParam (param) {
  const queryValue = getParameterByName(param)
  if (queryValue) {
    setCookie(param, queryValue, 365)
    return queryValue
  }
  return getCookie(param)
}
